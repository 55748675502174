import { AsyncPipe, CommonModule, DatePipe } from '@angular/common'
import { Component, EventEmitter, Output, inject } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CustomMenuComponent } from '@core/components/custom-menu/custom-menu.component'
import { AlertService } from '@core/services/alert.service'
import { ThemeService } from '@core/services/theme.service'
import { AppStateService } from '@core/states/app-state.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { TuiToNativeDateTransformerDirective } from '@core/ui/tui-to-native-date.directive'
import { ReleaseNoteFormService } from '@modules/update/states/release-note-form.service'
import { TUI_SANITIZER } from '@taiga-ui/core'
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify'
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor'
import { switchMap, throwError } from 'rxjs'
import { AdminRoutes, getAdminRoutes } from '../admin.routes'

@Component({
    selector: 'app-application-updates',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        AsyncPipe,
        TuiToNativeDateTransformerDirective,
        DatePipe,
        ...TaigaUiImports,
        NgxEditorModule,
        CustomMenuComponent,
    ],
    templateUrl: './application-updates.component.html',
    styleUrls: ['./application-updates.component.scss'],
    providers: [
        {
            provide: TUI_SANITIZER,
            useClass: NgDompurifySanitizer,
        },
    ],
})
export class ApplicationUpdatesComponent {
    private alertService = inject(AlertService)
    releaseNoteFormService = inject(ReleaseNoteFormService)
    appStateService = inject(AppStateService)
    themeService = inject(ThemeService)

    @Output() done = new EventEmitter<void>()

    readonly adminRoutes: AdminRoutes = getAdminRoutes()
    editor: Editor
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
        [],
    ]

    onSubmit() {
        this.alertService
            .confirm(
                'Create Release Note',
                'Are you sure you want to submit this Release Note? Every user will be notified.',
                'Yes',
            )
            .pipe(
                switchMap((value) =>
                    value
                        ? this.releaseNoteFormService.submitReleaseNote()
                        : throwError(() => new Error('Canceled')),
                ),
            )
            .subscribe({
                next: () => {
                    this.alertService.success('Successfully create a Release Note')
                    this.done.next()
                },
                error: (err) => {
                    this.alertService.error(err ?? 'Something went wrong. Please try again.')
                },
            })
    }
}
